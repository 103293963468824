.certificatesTitle {
    display: grid;
}

.coloredText {
    color: var(--primary);
}

.textTitle {
    color: var(--colorText);
}

.textNote {
    color: var(--color-black-45);
}
